
  import axios, { AxiosError } from 'axios';
  import { IUserData } from "@/store/UsersStore";
  import { defineComponent, ref, onMounted, onBeforeMount, watch } from 'vue';
  import { useRouter } from "vue-router";
  import { campHandleCPF } from '@/composables/DataValidation';
  import { useUsersStore } from "@/store/UsersStore";
  import useAlert from "@/composables/Alert";
  import {
    CampHeader,
    CampEmptyListFeedbackV2,
    CampTable,
    CampTableTd,
    CampTableTh
  } from '@/components';
  import modalRelationStore from './Components/modalRelationStore.vue';
  import { useLoaderStore } from "@/store/LoaderStore";
  import { useStoreStore } from '@/store/StoreStore';
  import CampModalDelRegister from '@/components/CampModalDelRegister.vue';
  import CampModalRecoverRegister from '@/components/CampModalRecoverRegister.vue';
  
  interface ICompetitorData extends Omit<IUserData, 'cpf'> {
    cpf_competitor: string;
  }
  
  interface IUserResponse {
    data: {
      error: boolean,
      message: string,
      data: IUserData[]
      errorCode: string
    }
  }
  
  interface IStoreCompetitorResponse {
    data: {
      error: boolean,
      message: string,
      data: ICompetitorData[]
      errorCode: string
    }
  }
  
  export default defineComponent({
    name: "UserList",
    components: {
      CampHeader,
      CampEmptyListFeedbackV2,
      CampTable,
      CampTableTd,
      CampTableTh,
      modalRelationStore,
      CampModalDelRegister,
      CampModalRecoverRegister
    },
    setup() {
      const storeStore = useStoreStore()
      const idStore = ref<null | number>(storeStore.getId)
      const router = useRouter();
      const relationProps = ref(false)
  
      const queryObject = ref<{
        isLoading: boolean,
        noError: boolean,
        data:  null | IUserData[]
      }>({
        isLoading: false,
        noError: false,
        data: null
      })
      const printCompetitors = ref<null | IUserData[]>(null)
      const keywordSearch = ref("")
  
      const relationData = ref<IUserData | null>(null)
      const loaderStore = useLoaderStore();
      const isLoading = ref(false)
  
      const usersStore = useUsersStore()
  
      const { showTimeAlert } = useAlert()
      const activeStatus = ref(true)
      const tableItem = ref<IUserData | null>(null)
      const toggleModalDelRegister = ref(false)
  
      function funcDetailRelationData(data) {
        relationProps.value = !relationProps.value
        relationData.value = data
      }
  
      // Functions
      async function handleGetUsers(id: number | null, onlyInactive = false) {
        isLoading.value = true
        try {
          let queryParams = ""
          if(typeof id === "number" && id > 0)
            queryParams += `id_store=${id}&`
          if(onlyInactive)
            queryParams += `only_inactive=1&`
          if(queryParams.length > 0)
            queryParams = `?${queryParams.slice(0, -1)}`
          const result: IUserResponse = await axios.get(`/api/getCompetitorConsultantList${queryParams}`)
          const { data } = result
          queryObject.value.data = data.data
          queryObject.value.noError = true
          queryObject.value.data = data.data
          printCompetitors.value = data.data
          usersStore.setCompanies(data.data)
        } catch (error) {
          if(error instanceof AxiosError) {
              showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
          usersStore.setCompanies(null)
        } finally {
          loaderStore.close()
          isLoading.value = false
        }
      }
  
      function handleFilterObjectsByKeyword(): boolean {
        if(!queryObject.value.data)
          return true
        printCompetitors.value = queryObject.value.data.filter((item) => {
          for (const key in item) {
            if (key !== 'checked') {
              const propValue = item[key];
              if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase())) {
                return true;
              }
              if (typeof propValue === 'number' && keywordSearch!.toString() === propValue.toString()) {
                return true;
              }
            }
          }
          return false;
        });
        return false
      }
  
      function handleDataLocalStorage() {
        const ss = JSON.parse(localStorage.getItem("ss")!)
        if(ss) {
          storeStore.setId(ss.i)
          storeStore.setFantasyName(ss.n)
        }
      }
  
      function handleModalDelRegister(item: IUserData) {
        toggleModalDelRegister.value = !toggleModalDelRegister.value
        tableItem.value = item
      }
  
      async function handleConfirmItemDel() {
        if(tableItem.value) {
          try {
            loaderStore.open()
            const result = await axios.delete(`/api/deleteCompetitor/${tableItem.value.id}`)
            showTimeAlert(`Consultor ${tableItem.value.name} excluído com sucesso!`)
            handleGetUsers(storeStore.getId)
            tableItem.value = null
          } catch (error) {
            if (error instanceof AxiosError) {
                showTimeAlert(error.response?.data.message, "error")
            } else {
              showTimeAlert("Algo deu errado!", "error")
            }
          } finally {
            loaderStore.close()
          }
        }
      }
  
      const toggleModalRecoverRegister = ref(false)
      function handleModalRecoverRegister(item: IUserData) {
        toggleModalRecoverRegister.value = !toggleModalRecoverRegister.value
        tableItem.value = item
      }
      async function handleConfirmItemRecover() {
        if(!tableItem.value)
          return
        try {
          loaderStore.open()
          await axios.put(`/api/recoverCompetitor/${tableItem.value.id}`)
          showTimeAlert(`Consultor ${tableItem.value.name} recuperado com sucesso!`)
          if(Array.isArray(printCompetitors.value) && printCompetitors.value.length > 1)
            handleGetUsers(storeStore.getId, true)
          else
            activeStatus.value = true
          tableItem.value = null
        } catch (error) {
          if(error instanceof AxiosError)
            showTimeAlert(error.response?.data.message, "error")
          else
            showTimeAlert("Algo deu errado!", "error")
        } finally {
          loaderStore.close()
        }
      }
  
      // Life Cycles
      onBeforeMount(() => handleDataLocalStorage())
  
      onMounted(() => handleGetUsers(storeStore.getId))
  
      watch(() => storeStore.getId, () =>isLoading.value = true)
  
      watch(() => storeStore.store, () => handleGetUsers(storeStore.getId))
  
      watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())
  
      watch(() => activeStatus.value, () => {
        if(activeStatus.value)
          handleGetUsers(storeStore.getId)
        else
          handleGetUsers(storeStore.getId, true)
      })
  
      return {
        router,
        isLoading,
        printCompetitors,
        queryObject,
        keywordSearch,
        campHandleCPF,
        activeStatus,
        tableItem,
        handleModalDelRegister,
        toggleModalDelRegister,
        handleConfirmItemRecover,
        toggleModalRecoverRegister,
        handleModalRecoverRegister,
        handleConfirmItemDel,
        relationData,
        funcDetailRelationData,
        relationProps
      }
    }
  })
  