
      import { defineComponent, ref, Ref, watch, defineProps } from "vue";
      import { IUserData } from "@/store/UsersStore";
      import axios, { AxiosError } from "axios";
      import useAlert from "@/composables/Alert";
  
  
      export default defineComponent({
          name: "modalRelationCompetitor",
          props:{
              relationData: Object,
              isChangeProps: Boolean
          
          },
          setup(props) {
              const modalRelationInfo = ref()
              const relationDataInfo = ref<any | never>([])
              const ownRelationInfo = ref()           
              const value1 = ref([])
              const options = ref<any>([])
              const isLoading = ref(false)
              const isLoadingButton = ref(false)
              const isLoadSelect = ref(false)
              const lenghtRealation = ref(0)
              const cpfCompetitor = ref('')
  
              
              const { showTimeAlert } = useAlert()
  
              function resetSelectValues() {
                  value1.value = []
                  lenghtRealation.value = 0
              }
  
           
  
  
              async function onSubmitFunction(data) {
                  isLoading.value = true
                  try {
                  const arrayRelation: any = []
                      value1.value.forEach(elem => {
                          arrayRelation.push({
                              cpf_competitor: relationDataInfo.value.length > 0 ? relationDataInfo.value[0].cpf_competitor : cpfCompetitor.value,
                              id_store: elem
                          })
                      })
                 
                      const response = await axios.post('/api/postCompetitorStore', arrayRelation)
  
                      await getRelation()
                       geDataRelation()
                      resetSelectValues()
                  } catch (error) {
                      if(error instanceof Error) {
  
                          showTimeAlert(error.message, "error")
                      }
                      
                  } finally {
                      isLoading.value = false
                      lenghtRealation.value = relationDataInfo.value.length
                  }
              }
  
              async function getRelation(loading: boolean | undefined = true) {
                  if(loading){
                      isLoading.value = true
                  }
                  isLoadingButton.value = true
                  try {
                      const response = await axios.get(`/api/getCompetitorStore/${ownRelationInfo.value.id}`)
                      relationDataInfo.value = response.data.data
                      if(relationDataInfo.value.length < 1) {
                        const response = await axios.get(`/api/getCompetitorView/${ownRelationInfo.value.id}`)
                        cpfCompetitor.value = response.data.data?.cpf
                      }
                  } catch (error) {
                      if(error instanceof AxiosError) {
                        if(error.response) {
                          if(error.response.data.message !== "O consultor não possui lojas cadastradas") {
                            showTimeAlert(error.response.data.message, "error")
                          }
                        } else {
                          showTimeAlert("Algo deu errado!", "error")
                        }
                      }
                  } finally {
                      isLoading.value = false
                      isLoadingButton.value = false
                      lenghtRealation.value = relationDataInfo.value.length
                  }
              }
  
              watch(
                  () => props.isChangeProps,
                  () => {
                      ownRelationInfo.value = props.relationData
                      getRelation()
                      geDataRelation()
                  }
              );  
  
              async function geDataRelation() {
                  isLoadSelect.value = true
                  options.value = []
                  try {
                      const response = await axios.get('/api/getStore')
                      modalRelationInfo.value = response.data.data
                      // options.value = []
                      modalRelationInfo.value.forEach(element => {       
                          if(!relationDataInfo.value.some(elem => elem.id === element.id)){
                              options.value.push({
                                  value: element.id,
                                  label: element.fantasy_name + ' - ' + element.cnpj
                              })
                         }    
  
                      
                  });
                  isLoadSelect.value = false
                  } catch (error) {
                      isLoadSelect.value = false
                  }
              }
          
    
              async function deletRelation(idStore){
                  isLoadingButton.value = true
                  const cpf_competitor = relationDataInfo.value.length > 0 ? relationDataInfo.value[0].cpf_competitor : cpfCompetitor.value
                  try {
                      const response = await axios.delete(`/api/deleteCompetitorStore/${cpf_competitor}/${idStore}`)
                      getRelation(false)
                      geDataRelation()
                     
                  } catch (error) {
  
                  } finally {
                      // isLoadingButton.value = false
                  }
              }
        
  
  
         
              
              return {
                  modalRelationInfo,
                  ownRelationInfo,
                  value1,
                  options,
                  onSubmitFunction,
                  relationDataInfo,
                  resetSelectValues,
                  deletRelation,
                  isLoading,
                  isLoadSelect,
                  isLoadingButton,
                  lenghtRealation
              }
          }
  
      })
  
  